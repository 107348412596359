
import { Options, Vue } from "vue-class-component";
import Presentation from "@/components/Presentation.vue";

@Options({
  components: {
    Presentation,
  },
})
export default class Home extends Vue {}
